/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Sí, somos cyborgs, no lo podemos seguir negando, gracias a Internet hemos creado nuestro propio yo digital. En la siguiente charla TED explica todo esto detalladamente. Yo me quedo con dos ideas; la perdida de la reflexión al estar siempre ocupados y que la tecnología nos ha hecho más humanos."), "\n", React.createElement(_components.p, null, "[ted id=1050]"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
